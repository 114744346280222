export default {
  About: 'About',
  Resume: 'Resume',
  Projects: 'Projects',
  Stats: 'Stats',
  Contact: 'Contact',
  aboutMe: 'About Me',

  resume: 'Resume',
  education: 'education',
  experience: 'experience',
  achievements: 'achievements',
  skills: 'skills',
  tools: 'tools',
  references: 'references',

  Couperin_degree: 'Baccalauréat Scientifique, specialty in Computer sciences',
  Fenelon_degree: 'Preparatory class for the Grandes Écoles, MPSI then MP*',
  EMSE_degree: 'Engineering degree specialized in Microelectronics and Computer Sciences',

  unbabel_company: 'Unbabel',
  unbabel_position: 'English to French translator',
  unbabel_point0: 'Translate documents and texts of all types from various companies and industries',
  unbabel_point1: 'Started as a simple translator, and then moved to a Senior Editor, in charge of checking translations before they are sent to the client',

  ISM_company: 'Institute of Movement Sciences',
  ISM_position: 'Internship',
  ISM_point0: 'Worked on designing and builing a remote-controlled helium-powered low-noise drone, designed to be used in a theater play',
  ISM_point1: 'In charge of designing a 3D model of the drone, and then creating a low-weight PCB capable of controlling the required motors and sensors',
  ISM_point2: 'Used a Raspberry Pi Zero and an Xbox controller to create an easy way to control the drone',

  PI_company: 'Saint-Étienne School of Mines - Flexible electronics laboratory',
  PI_position: 'Industrial project',
  PI_point0: 'Worked on creating a prototype for a connected wearable ECG monitor',
  PI_point1: 'As a team of two students, we created a rigid proof of concept for this device using an STM32WB55 microcontroller',
  PI_point2: 'I mainly worked on the code and the component\'s selection, and managed to get the average power consumption of the whole device from 10 mW down to 140 µW',

  Apple_company: 'Apple',
  Apple_position: 'PMU AMS-DV Verification Intern',
  Apple_point0: 'Worked on the verification of the next generation of Apple\'s PMU chips',
  Apple_point1: 'Created new VAMS components to speed simulations, and did measurements on physical chips to test experimental features',

  Some_stats_about_me: 'Some stats about me',
  Some_stats_about_the_website: 'Some stats about the website',
  Current_age: 'Current age',
  Countries_visited: 'Countries visited',
  Current_city: 'Current city',
  Github_stars: 'Stars this repository has on github',
  Watchers: 'Number of people watching this repository',
  Forks: 'Number of forks',
  Spoons: 'Number of spoons',
  Linter_warnings: 'Number of linter warnings',
  Open_issues: 'Number of open issues on Github',
  Last_update: 'Last updated on',
  Javascript_lines: 'Lines of Javascript powering this website',
};
